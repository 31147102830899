var $ = require("jquery");
var Picture = require("./includes/picture_functions")

require ('./includes/form')


$(document).ready(function(){

    Picture.lazy_load_launch();
    Picture.background_is_picture_launch();


    require('./includes/global')
});

// require('./global_react')