import { bodyFixPosition, bodyUnfixPosition } from "../../../js/includes/body_fixed";

let fade_time = 300;

window.popup_show = popup_show;
window.popup_close = popup_close;
window.popup_set_type = popup_set_type;

window.confirmation_show = confirmation_show;
window.confirmation_close = confirmation_close;


// Show Popup
// cls - class of popup. (Like .Popup.Type)
// optional_title - some popups can change title
// optional_text - some popups can change message
// function popup_show(cls, optional_title, optional_text, callbacks){
//     var popup = $('.Popup.'+cls);
//     var hider = $('.Hider.'+cls);
//
//     if(typeof optional_title !== 'undefined'){
//         popup.find('.Popup_Title').html(optional_title);
//     }
//     if(typeof optional_text !== 'undefined'){
//         popup.find('.Popup_Text').html(optional_text);
//     }
//
//     if(typeof callbacks === 'object'){
//         if(typeof callbacks.after_close === 'function'){
//             popup.data({after_close: callbacks.after_close});
//         }
//     }
//
//     hider.fadeIn(fade_time);
//     popup.addClass('Visible');
//    // $("body").css("overflow","hidden");
// }

function popup_show(popup_setting) {
    console.log('popup_show');
    let {
        cls = ".Autf",
        optional_title = undefined,
        optional_text = undefined,
        anim = "fade",
        callbacks = undefined
    } = popup_setting || {};

    let scroll_width = getScrollBarWidth();



    var popup = $(".Popup." + cls);
    var hider = $(".Hider." + cls);

    if (typeof callbacks === "object") {
        if (typeof callbacks.after_close === "function") {
            popup.data({ after_close: callbacks.after_close });
        }
    }

    if (typeof optional_title !== "undefined") {
        popup.find(".Popup_Title").html(optional_title);
    }
    if (typeof optional_text !== "undefined") {
        popup.find(".Popup_Text").html(optional_text);
    }

    /* тест костыля с инета */

    bodyFixPosition();

    // if(callbacks !== undefined){
    //     callbacks();
    // }
	if(anim === "fade"){
		hider.fadeIn(fade_time);
	} else {
		hider
			.removeClass("modal-exit")
			.removeClass("modal-exit-active")
			.addClass("modal-enter")
			.addClass("modal-enter-active");
	}


    popup.addClass("Visible");


    hider.addClass('active');

    setTimeout(function () {
        hider.css({pointerEvents: "auto"});
    }, 200);


    if($(window).width() > 767){
        $(".main_menu").css('overflow', 'hidden');
        $(".main_menu").css('padding-right', scroll_width+'px');
        $("body").css('overflow', 'hidden');
        $("body").css('padding-right', scroll_width+'px');
    }

}

// Close popup
function popup_close(popup_setting) {
    console.log('popup_close');
    let { cls = ".Autf", anim="fade", callbacks = undefined } = popup_setting || {};

    var popup = $(".Popup." + cls);
    var hider = $(".Hider." + cls);

    let after_close = popup.data("after_close");

    if($(window).width() > 767){
        $(".main_menu").css('overflow', '');
        $(".main_menu").css('padding-right', '');
        $("body").css('overflow', '');
        $("body").css('padding-right', '');
    }

    if(anim === "fade"){
	    hider.fadeOut(fade_time);
    } else{
	    hider
		    .removeClass("modal-enter")
		    .removeClass("modal-enter-active")
		    .addClass("modal-exit")
		    .addClass("modal-exit-active");
    }

    hider.css({pointerEvents: "none"});

    popup.removeClass("Visible");
    hider.removeClass('active');

    /* тест костыля с инета */
    bodyUnfixPosition();


    // if (callbacks !== undefined) {
    //     callbacks();
    // }

    if (typeof after_close === "function") {
        after_close();
        popup.data("after_close", "");
    }
}

// Set or remove type class on popup. Remove all classes except .Popup and _cls_. Add class of _type_
// type - class (no dot) of type. Example type - Success, Error.
function popup_set_type(cls, type) {
    var popup = $(".Popup." + cls);
    if (type === "") {
        popup.attr("class", "Popup " + cls);
        return;
    }

    var classList = popup.attr("class").split(/\s+/);
    $.each(classList, function(index, item) {
        if (item !== "Popup" && item !== cls) {
            popup.removeClass(item);
        }
    });
    popup.addClass(type);
}

function getScrollBarWidth () {
    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild (inner);

    document.body.appendChild (outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 == w2) w2 = outer.clientWidth;

    document.body.removeChild (outer);

    return (w1 - w2);

    // TODO: Try this method of calc
    /*
        var documentWidth = document.documentElement.clientWidth;
        var windowWidth = window.innerWidth;
        var scrollBarWidth = windowWidth - documentWidth;
        return scrollBarWidth;
        */
};


// test
$('body').on('click', '.woman-header-group__title', function () {
    confirmation_show({
        optional_title: "Delete",
        optional_text: "Are you sure?",
    });
});

function confirmation_show(settings){
    console.log('confirmation_show');
    let {
        //cls = undefined,
        optional_title = undefined,
        optional_text = undefined,
        anim = "fade",
        buttons = []
    } = settings || {};

    // Test
    // buttons = [
    //     {
    //         "class": "btn btn-red confirm_ok",
    //         "text": "OK",
    //         "callback": function (args) {
    //             console.log("confirm_ok");
    //         },
    //         "hider_settings": {
    //             anim: "fade",
    //         }
    //     },
    //     {
    //         "class": "btn btn-red confirm_cancel",
    //         "text": "CANCEL",
    //         "callback": function (args) {
    //             console.log("confirm_cancel");
    //         },
    //         "hider_settings": {
    //             anim: "fade",
    //         }
    //     }
    // ];

    // if(cls === undefined){
    //     return
    // }

    let scroll_width = getScrollBarWidth();

    var popup = $(".Popup.Confirmation");
    var hider = $(".Hider.Confirmation");
    var buttons_container = popup.find(".Popup_Buttons");
    buttons_container.html("");

    // if (typeof callbacks === "object") {
    //     if (typeof callbacks.after_close === "function") {
    //         popup.data({ after_close: callbacks.after_close });
    //     }
    // }

    // Set popup title
    if (typeof optional_title !== "undefined") {
        popup.find(".Popup_Title").html(optional_title);
    }

    // Set popup text
    if (typeof optional_text !== "undefined") {
        popup.find(".Popup_Text").html(optional_text);
    }

    // Set popup buttons
    if (typeof buttons === "object") {
        for(let button of buttons){
            let button_element = $("<div class='"+(button.class || "")+"'>"+(button.text || "")+"</div>");
            console.log(button_element);
            if(typeof button.callback === "function"){
                button_element.on('click', function () {
                    button.callback();
                    confirmation_close(button.hider_settings);
                });
            }


            if(typeof button.callback === "function"){
                button_element.data({callback: button.callback});
            }

            buttons_container.append(button_element);
        }
    }


    bodyFixPosition();

    // if(callbacks !== undefined){
    //     callbacks();
    // }

    if(anim === "fade"){
        hider.fadeIn(fade_time);
    } else {
        hider
            .removeClass("modal-exit")
            .removeClass("modal-exit-active")
            .addClass("modal-enter")
            .addClass("modal-enter-active");
    }


    popup.addClass("Visible");

    hider.addClass('active');

    setTimeout(function () {
        hider.css({pointerEvents: "auto"});
    }, 200);


    if($(window).width() > 767){
        $(".main_menu").css('overflow', 'hidden');
        $(".main_menu").css('padding-right', scroll_width+'px');
        $("body").css('overflow', 'hidden');
        $("body").css('padding-right', scroll_width+'px');
    }
}

function confirmation_close(settings){
    console.log('confirmation_close');
    let {
        //cls = undefined,
        anim="fade",
        //callbacks = undefined
    } = settings || {};

    var popup = $(".Popup.Confirmation");
    var hider = $(".Hider.Confirmation");
    var buttons_container = popup.find(".Popup_Buttons");

    let after_close = popup.data("after_close");

    if($(window).width() > 767){
        $(".main_menu").css('overflow', '');
        $(".main_menu").css('padding-right', '');
        $("body").css('overflow', '');
        $("body").css('padding-right', '');
    }

    if(anim === "fade"){
        hider.fadeOut(fade_time);
    } else{
        hider
            .removeClass("modal-enter")
            .removeClass("modal-enter-active")
            .addClass("modal-exit")
            .addClass("modal-exit-active");
    }

    hider.css({pointerEvents: "none"});

    popup.removeClass("Visible");
    hider.removeClass('active');

    /* тест костыля с инета */
    bodyUnfixPosition();

    if (typeof after_close === "function") {
        after_close();
        popup.data("after_close", "");
    }

    buttons_container.html("");
}

//auth_popup
require("./auth_popup/auth_popup");
