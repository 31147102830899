export const bodyFixPosition = (callback = null) => {
    setTimeout(function() {
        /* Ставим необходимую задержку, чтобы не было «конфликта» в случае, если функция фиксации вызывается сразу после расфиксации (расфиксация отменяет действия расфиксации из-за одновременного действия) */

        if (!document.body.hasAttribute("data-body-scroll-fix")) {
            // Получаем позицию прокрутки
            let scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

            const scroll_width = scrollWidth();

            // Ставим нужные стили
            document.body.setAttribute("data-body-scroll-fix", scrollPosition); // Cтавим атрибут со значением прокрутки
            document.body.style.overflow = "hidden";
            document.body.style.position = "fixed";
            document.body.style.top = "-" + scrollPosition + "px";
            document.body.style.left = "0";
            document.body.style.width = "100%";
            document.body.style.paddingRight = scroll_width + "px";
            // document.querySelector(".main_menu").style.paddingRight = scroll_width + "px";

            console.log("callback", callback);

            if (callback !== null) {
                callback();
            }

        }
    }, 15); /* Можно задержку ещё меньше, но у меня работало хорошо именно с этим значением на всех устройствах и браузерах */
};

export const bodyUnfixPosition = (callback = null, obj) => {
    const { scrollTo = false } = obj === undefined ? {} : obj;
    //console.log()
    if (document.body.hasAttribute("data-body-scroll-fix")) {
        // Получаем позицию прокрутки из атрибута
        let scrollPosition = document.body.getAttribute("data-body-scroll-fix");

        // Удаляем атрибут
        document.body.removeAttribute("data-body-scroll-fix");

        // Удаляем ненужные стили
        document.body.style.overflow = "";
        document.body.style.position = "";
        document.body.style.top = "";
        document.body.style.left = "";
        document.body.style.width = "";
        document.body.style.paddingRight = "";
        // document.querySelector(".main_menu").style.paddingRight = "";

        if (callback !== null) {
            callback();
        }

        if (scrollTo === false) {
            // Прокручиваем страницу на полученное из атрибута значение
            window.scroll(0, scrollPosition);
        }
    }
};

export const scrollWidth = () => {
    let inner = document.createElement("p");
    inner.style.width = "100%";
    inner.style.height = "200px";

    let outer = document.createElement("div");
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);

    document.body.appendChild(outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = "scroll";
    var w2 = inner.offsetWidth;
    if (w1 == w2) w2 = outer.clientWidth;

    document.body.removeChild(outer);

    return w1 - w2;
};

const scroll_width = scrollWidth();

export const lockScroll = (elem = "body") => {
    const elem_dom = document.querySelector(elem);
    if (elem_dom.addEventListener) {
        elem_dom.addEventListener(
            "DOMMouseScroll",
            (elem_dom.onmousewheel = function(e) {
                e.preventDefault();
            }),
            { passive: false }
        );
    } else {
        elem_dom.onmousewheel = function(e) {
            window.event.returnValue = false;
        };
    }
};

export const unlockScroll = (elem = "body") => {
    const elem_dom = document.querySelector(elem);
    if (elem_dom.removeEventListener) {
        elem_dom.removeEventListener("DOMMouseScroll", elem_dom.onmousewheel, { passive: false });
        elem_dom.onmousewheel = null;
    } else {
        elem_dom.onmousewheel = null;
    }
};
