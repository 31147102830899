// Include in document ready

// Works for any checkbox
$(".checkbox").on("click", function() {
    if (!$(this).hasClass("active")) {
        $(this).addClass("active");
    } else {
        $(this).removeClass("active");
    }
});

