var $ = require("jquery");
import {bodyFixPosition, bodyUnfixPosition} from "./body_fixed";

const {detect} = require("detect-browser");
const browser = detect();
//import Validator from "./validator";
const {checkbox} = require("./form_checkbox");
import {setCookie, getCookie} from "./cookies";

const PrintContent = require('./print_content')
//require('../libs/datepicker/datepicker')
require('/resources/js/includes/spinner')

const CSRF = require('./csrf');

var ajax_request;

/*PHONE MASK*/
//require("intl-tel-input/build/js/intlTelInput-jquery");

CSRF.default();

/*captcha*/
const SITE_KEY = '6LenTzoqAAAAADUJquEqP9uRvFyuBCcvpzpKR4uo';
var captcha_token = null;
window.captcha_token = null;

// if(typeof grecaptcha !== 'undefined'){
//     grecaptcha.ready(function() {
//         console.log("CAPTCHA READY");
//         grecaptcha.execute(SITE_KEY).then(function(token) {
//             captcha_token = token;
//             window.captcha_token = token
//             console.log("CAPTCHA EXECUTE");
//         });
//     });
// }else{
//     console.warn("CAPTCHA UNDEFINED");
// }

/*captcha*/

import(
    /* webpackChunkName: "datepicker" */
    `../libs/datepicker/datepicker`
).then(function (module) {
    const datepicker = module.default;
    console.log(module, datepicker);

    if ($(window).width() >= 996) {

        $.fn.datepicker.language['en'] = {
            days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            months: ['January','February','March','April','May','June', 'July','August','September','October','November','December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            today: 'Today',
            clear: 'Clear',
            dateFormat: 'mm/dd/yyyy',
            timeFormat: 'hh:ii aa',
            firstDay: 0
        }

        $.fn.datepicker.language['ru'] = {
            days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
            daysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            daysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            months: ['Январь','Февраль','Март','Апрель','Май','Июнь', 'Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
            monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
            today: 'Сегодня',
            clear: 'Очистить',
            dateFormat: 'dd.mm.yyyy',
            timeFormat: 'hh:ii aa',
            firstDay: 1
        }

        $.fn.datepicker.language['uk'] = {
            days: ["Неділя", "Понеділок", "Вівторок", "Середа", "Четвер", "П'ятниця", "Субота"],
            daysShort: ["Нед", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Суб"],
            daysMin: ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
            months: ["Cічень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"],
            monthsShort: ["Січ", "Лют", "Бер", "Кві", "Тра", "Чер", "Лип", "Сер", "Вер", "Жов", "Лис", "Гру"],
            today: "Сьогодні",
            clear: "Очистити",
            format: "dd.mm.yyyy",
            weekStart: 1
        }

        $('#datepicker').attr('type', 'text');

        $('#datepicker').datepicker({
            minDate: new Date(),
            language: typeof window.locale !== 'undefined' ? window.locale : 'en',
            dateFormat: 'mm/dd/yyyy',
            timepicker: true,
            position: "left bottom",
            // timeFormat: 'hh:ii'
        })
    }
});


if ($(window).width() < 996 && browser.os == "iOS") {
    //alert("ddd")
    $(".mobile_Nav").addClass("mobile_Nav_ios");
}

var pathname = window.location.pathname;

$('.main_menu_list>li>a[href="' + pathname + '"]').addClass("active");

var btn = $(".btn_top");

$(window).scroll(function () {
    //высота всей страницы
    let height = $("body").outerHeight(true);
    //50% от высоты всей страницы
    height = (height * 50) / 100;

    if ($(window).scrollTop() > height) {
        btn.addClass("show");

        if ($(window).width() < 1130) {
            btn.addClass("bottom");
        }
    } else {
        btn.removeClass("show");

        if ($(window).width() < 1130) {
            btn.removeClass("bottom");
        }
    }

    var $element = $("footer");
    var scroll = $(window).scrollTop() + $(window).height();
    var offset = $element.offset().top;

    if (scroll > offset) {
        btn.addClass("white");
    } else {
        btn.removeClass("white");
    }
});

btn.on("click", function (e) {
    e.preventDefault();
    $("html, body").animate({scrollTop: 0}, "300");
});

$(window).one('scroll.once click.once mousemove.once touchstart.once', function (e) {
    $(window).off("scroll.once click.once mousemove.once touchstart.once");

    // Cookies
    setTimeout(() => {
        let cookies_policy = getCookie("policy");
        console.log(cookies_policy, "cookies");
        if (cookies_policy === undefined || cookies_policy === null || cookies_policy === false) {
            $(".cookies-popup").fadeIn("slow");
        }
    }, 3000);

    $('.open-articles').click(function () {
        const targetElement = $("#articles-popup");
        if (targetElement) targetElement.toggleClass("articles-popup-open");
    })

    $('.articles-wrapper').click(function (event) {
        if (event.target === this) $(this).removeClass("articles-popup-open");
    });

    /* =========== flickity START =========== */
    Promise.all([
        import(
            /* webpackChunkName: "intlTelInput" */
            `intl-tel-input`
            ),
        import(
            /* webpackChunkName: "inputmask" */
            `../libs/jq_input_mas`
            ),
    ]).then(function (modules) {
        //console.log("Preloaded modules:", modules);

        // Expand modules into variables for more convenient use
        const [intlTelInputModule, inputmaskModule] = modules;
        const intlTelInput = intlTelInputModule.default;
        const inputmask = inputmaskModule.default;

        // window.intlTelInputGlobals.loadUtils("build/js/utils.js");

        // let phone_input = $('input[name="phone"]');
        // let phone_input_p = document.querySelector('input[name="phone"]');
        // let mask = undefined; //default
        // if (phone_input.length > 0) {
        //
        //     console.log(phone_input_p);
        //
        //     phone_input.intlTelInput({
        //         initialCountry: "us",
        //         preferredCountries: ["ua", "us", "gb", "de", "ca", "au", "nz"],
        //         separateDialCode: true,
        //         // autoPlaceholder: "aggressive",
        //         geoIpLookup: function (callback) {
        //             $.get('https://ipinfo.io', function () {
        //             }, "jsonp").always(function (resp) {
        //                 let countryCode = (resp && resp.country) ? resp.country : "";
        //                 callback(countryCode);
        //             });
        //         },
        //         utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.14/js/utils.js"
        //     });
        //
        //     /* ADD A MASK IN PHONE INPUT */
        //     // $(document).ready(function() {
        //     setTimeout(function () {
        //
        //         mask = phone_input.attr('placeholder').replace(/[0-9]/g, 9);
        //         phone_input.attr('placeholder', window.js_strings["phone_label"]);
        //
        //         if(typeof mask !== 'undefined'){
        //             phone_input.inputmask({
        //                 mask: mask,
        //                 clearIncomplete: false,
        //                 showMaskOnHover: false
        //             });
        //         }
        //
        //     }, 1500);
        //     // });
        //
        //
        //     /* When changing the country, change the phone mask */
        //     phone_input.on("countrychange", function (e, countryData) {
        //         $(this).val('');
        //
        //         mask = $(this).attr('placeholder').replace(/[0-9]/g, 9);
        //         $(this).attr('placeholder', window.js_strings["phone_label"]);
        //
        //         if (typeof mask !== 'undefined') {
        //             $(this).inputmask({
        //                 mask: mask,
        //                 clearIncomplete: false,
        //                 showMaskOnHover: false
        //             });
        //         }
        //
        //     });
        //
        // }

        setTimeout(function (){
            let phoneInput = $('input[name="phone"]');

            if(typeof phoneInput !== 'undefined' && phoneInput.length > 0){

                phoneInput.map((index, item) => {
                    initIntlTel(item);
                })

            }
        }, 2000)


        function initIntlTel(phoneInput) {

            //$(phoneInput).val("+3551112223333");
            //$(phoneInput).trigger("input");

            let initIntlTelInput = intlTelInput(phoneInput, {
                initialCountry: "us",
                preferredCountries: ["ua", "us", "gb", "de", "ca", "au", "nz"],
                separateDialCode: true,
                // autoPlaceholder: "aggressive",
                geoIpLookup: function (callback) {
                    $.get('https://ipinfo.io', function () {
                    }, "jsonp").always(function (resp) {
                        let countryCode = (resp && resp.country) ? resp.country : "";
                        callback(countryCode);
                    });
                },
                utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.14/js/utils.js"
            });

            initIntlTelInput.promise.then(() => {

                // Get the selected country data to know which country is selected.
                let selectedCountryData = initIntlTelInput.getSelectedCountryData();

                $(phoneInput)[0].dataset.dialCode = selectedCountryData.dialCode;
                $(phoneInput)[0].dataset.iso2 = selectedCountryData.iso2;
                $(phoneInput)[0].dataset.name = selectedCountryData.name;

                console.log("[selectedCountryData]", $(phoneInput)[0].dataset.dialCode);

                /* ADD A MASK IN PHONE INPUT */
                initPhoneMask(phoneInput, selectedCountryData);

            }).catch(() => {
                console.log('error');
            });

            /* When changing the country, change the phone mask */
            $(phoneInput).on("countrychange", function (e, countryData) {

                // Get the selected country data to know which country is selected.
                let selectedCountryData = initIntlTelInput.getSelectedCountryData();

                $(phoneInput)[0].dataset.dialCode = selectedCountryData.dialCode;
                $(phoneInput)[0].dataset.iso2 = selectedCountryData.iso2;
                $(phoneInput)[0].dataset.name = selectedCountryData.name;

                console.log("[selectedCountryData]", $(phoneInput)[0].dataset.dialCode);

                /* ADD A MASK IN PHONE INPUT */
                initPhoneMask(phoneInput, selectedCountryData);

            });

        }

        /*PHONE MASK*/
        function initPhoneMask(phoneInput, selectedCountryData) {

            let mask = undefined; //default

            // Get an example number for the selected country to use as placeholder.
            let newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, false, intlTelInputUtils.numberFormat.INTERNATIONAL);
            // Remove a country code from the placeholder.
            newPlaceholder = newPlaceholder.replace("+", "").slice(selectedCountryData.dialCode.length).trim();

            // Reset the phone number input.
            //$(phoneInput).val('');

            $(phoneInput).attr('placeholder', window.js_strings["phone_label"]);

            mask = newPlaceholder.replace(/[0-9]/g, "9");

            if (typeof mask !== 'undefined') {

                $(phoneInput).inputmask({
                    mask: mask,
                    clearIncomplete: false,
                    showMaskOnHover: false
                });

            }

        }

    });

    import(
        /* webpackChunkName: "validator" */
        `./validator`
    ).then(function (module) {
        const Validator = module.default;

        //contact valid
        var validator_contact = new Validator($("#form-contact"));
        validator_contact.watch();
        // Contact button
        $(".btn-contact").on("click", function (e) {
            e.preventDefault();
            var result = validator_contact.validate();

            if (result !== false) {
                result.append("locale", locale);
                result.append("_token", $('meta[name="csrf-token"]').attr("content"));
                result.append("dial_code", $("#form-contact").find($(".iti__selected-flag")).attr('title'));


                console.log(baseUrl);
                ajax_request = $.ajax({
                    url: baseUrl + "/request_contact",
                    type: "POST",
                    cache: false,
                    data: result,
                    processData: false,
                    contentType: false,
                    beforeSend: function() {},
                    success: function(response) {
                        if (response["status"] === "1") {
                            console.log("OK");
                            //document.location = response['redirect'];
                            window.popup_set_type("ThankYou", "Success");
                            window.popup_show({cls: "ThankYou", optional_text: js_strings["thankyou_success"]});
                            $(".show-new-modal.Log_Link").click();
                        } else {
                            if (response["type"] === "wrong") {
                                validator_contact.general_error_show(js_strings["error_wrong_credentials"]);
                            } else if (response["type"] === "validation") {
                                console.log("error", response);
                            } else if (response["type"] === "exists") {
                                validator_contact.general_error_show(js_strings["error_exists"]);
                            }
                        }
                    },
                    error: function (response) {
                        console.log("error", response);
                    }
                });
            }
        });

        //Payment valid
        var validator_pay = new Validator($("#form-pay"));
        validator_pay.watch();
        // pay btn
        $(".btn-pay").on("click", function (e) {
            e.preventDefault();
            let result = validator_pay.validate();
            let id_form = $('#form-pay');
            let payment_system = id_form.find('select[name=payments_list]').val() || "asset_payments";

            if (result !== false) {

                let payment_type = id_form.find('input[name=payment_type]').val();
                let name_product = id_form.find('input[name=name_product]').val();
                let phone_data = id_form.find($(".iti__selected-flag")).attr('title');
                let phone_data_array = phone_data.split(':');
                let phone_country = phone_data_array[0];
                let phone_dial_code = phone_data_array[1];

                let data = id_form.find('input[name=data]').val();
                let var_data = "";
                try {
                    var_data = JSON.parse(data);
                } catch (e) {
                    var_data = null;
                }

                if (payment_type === "service_plan") {
                    if (typeof var_data.item !== "undefined") {
                        if (var_data.item === "1") {
                            // Analytics event
                            if (typeof gtag !== "undefined") {
                                gtag('event', 'send', {'event_category': 'pay1', 'event_action': 'send',});
                            }
                        } else if (var_data.item === "2") {
                            // Analytics event
                            if (typeof gtag !== "undefined") {
                                gtag('event', 'send', {'event_category': 'pay2', 'event_action': 'send',});
                            }
                        } else if (var_data.item === "3") {
                            // Analytics event
                            if (typeof gtag !== "undefined") {
                                gtag('event', 'send', {'event_category': 'pay3', 'event_action': 'send',});
                            }
                        }

                    }
                }


                window.location.replace(window.baseUrl + "/api/" + payment_system + "/checkout?" +
                    "name=" + id_form.find('input[name=name]').val() + "&" +
                    "phone=" + id_form.find('input[name=phone]').val() + "&" +
                    "phone_country=" + phone_country + "&" +
                    "phone_dial_code=" + phone_dial_code + "&" +
                    "email=" + id_form.find('input[name=email]').val() + "&" +
                    "message=" + id_form.find('[name=message]').val() + "&" +
                    "id_product=" + id_form.find('input[name=id_product]').val() + "&" +
                    "name_product=" + name_product + "&" +
                    "price=" + id_form.find('input[name=price_product]').val() + "&" +
                    "women_id=" + id_form.find('input[name=women_id]').val() + "&" +
                    "currency=" + id_form.find('input[name=currency]').val() + "&" +
                    "data=" + id_form.find('input[name=data]').val() + "&" +
                    "user_id=" + id_form.find('input[name=user_id]').val() + "&" +
                    "payment_type=" + payment_type + "&" +
                    "redirect_success=" + window.location.href + "?status=success" + "&" +
                    "redirect_error=" + window.location.href + "?status=error"
                );
            }
        });

        // popup valid
        var validator_login = new Validator($("#form-login"));
        var validator_reg = new Validator($("#form-reg"));
        var validator_restore = new Validator($("#form-restore"));
        var validator_support = new Validator($("#form-Support"));
        var validator_RequestCall = new Validator($("#form-request-call"));
        var validator_ScheduleComplimentaryCall = new Validator($("#form-ScheduleComplimentaryCall"));
        var validator_BookMarchmakersService = new Validator($("#form-BookMarchmakersService"));
        var validator_subscribe_news = new Validator($("#form-subscribe-news"));

        validator_login.watch();
        validator_reg.watch();
        validator_restore.watch();
        validator_support.watch();
        validator_RequestCall.watch();
        validator_ScheduleComplimentaryCall.watch();
        validator_BookMarchmakersService.watch();
        validator_subscribe_news.watch();
        var ajax_request;

        // Register button
        $(".btn-reg_fn").on("click", function (e) {
            e.preventDefault();
            var result = validator_reg.validate();
            if (result !== false) {
                result.append("locale", locale);
                result.append("_token", $('meta[name="csrf-token"]').attr("content"));

                if (typeof grecaptcha !== 'undefined') {
                    grecaptcha.execute(SITE_KEY, {action: 'submit'}).then(function (token) {
                        captcha_token = token;

                        result.append("captcha_token", captcha_token);

                        ajax_request = $.ajax({
                            url: baseUrl + "/register",
                            type: "POST",
                            cache: false,
                            data: result,
                            processData: false,
                            contentType: false,
                            beforeSend: function () {
                            },
                            success: function (response) {
                                if (response["status"] === "1") {
                                    // Analytics event
                                    if (typeof gtag !== "undefined") {
                                        gtag('event', 'send', {'event_category': 'lead', 'event_action': 'send',});
                                    }

                                    if (typeof response['redirect'] !== "undefined") {
                                        console.log("redirecting...");
                                        document.location.href = response['redirect'];
                                    } else {
                                        document.location.reload();
                                    }
                                    // window.popup_close({cls:'Autf',scrollOff:'body'});
                                    // window.popup_set_type("ThankYou", "Success");
                                    // window.popup_show({ cls: "ThankYou", optional_text: js_strings["success_check_availability"] });

                                } else {
                                    if (response["type"] === "wrong") {
                                        validator_reg.general_error_show(js_strings["error_wrong_credentials"]);
                                    } else if (response["type"] === "validation") {
                                        console.log("error", response);
                                    } else if (response["type"] === "exists") {
                                        validator_reg.general_error_show(js_strings["error_exists"]);
                                    }
                                }
                            },
                            error: function (response) {
                                console.log("error", response);
                            }
                        });
                    })
                }
            }
        });

        // Register button
        $(".btn-login").on("click", function (e) {
            e.preventDefault();
            var result = validator_login.validate();
            if (result !== false) {
                result.append("locale", locale);
                result.append("_token", $('meta[name="csrf-token"]').attr("content"));

                if (typeof grecaptcha !== 'undefined') {
                    // grecaptcha.execute(SITE_KEY).then(function(token) {
                    grecaptcha.execute(SITE_KEY, {action: 'submit'}).then(function (token) {

                        captcha_token = token;

                        result.append("captcha_token", captcha_token);
                        ajax_request = $.ajax({
                            url: baseUrl + "/auth",
                            type: "POST",
                            cache: false,
                            data: result,
                            processData: false,
                            contentType: false,
                            beforeSend: function () {
                            },
                            success: function (response) {
                                if (response["status"] === "1") {
                                    console.log("OK");
                                    console.log("response['redirect'] " + response['redirect'])
                                    if (typeof response['redirect'] !== "undefined") {
                                        console.log("redirecting...");
                                        document.location.href = response['redirect'];
                                    } else {
                                        document.location.reload();
                                    }
                                    //window.popup_show("Notf_Good", svg_good, "Ваш заказ принят в обратботку")
                                } else {
                                    if (response["type"] === "wrong") {
                                        validator_login.general_error_show(js_strings["error_wrong_credentials"]);
                                    } else if (response["type"] === "validation") {
                                        console.log("error", response);
                                    } else if (response["type"] === "verification") {
                                        console.log(js_strings["error_verification"]);
                                        validator_login.general_error_show(js_strings["error_verification"]);
                                    }
                                }
                            },
                            error: function (response) {
                                console.log("error", response);
                            }
                        });
                    });
                } else {
                    ajax_request = $.ajax({
                        url: baseUrl + "/auth",
                        type: "POST",
                        cache: false,
                        data: result,
                        processData: false,
                        contentType: false,
                        beforeSend: function () {
                        },
                        success: function (response) {
                            if (response["status"] === "1") {
                                console.log("OK");
                                console.log("response['redirect'] " + response['redirect'])
                                if (typeof response['redirect'] !== "undefined") {
                                    console.log("redirecting...");
                                    document.location.href = response['redirect'];
                                } else {
                                    document.location.reload();
                                }
                                //window.popup_show("Notf_Good", svg_good, "Ваш заказ принят в обратботку")
                            } else {
                                if (response["type"] === "wrong") {
                                    validator_login.general_error_show(js_strings["error_wrong_credentials"]);
                                } else if (response["type"] === "validation") {
                                    console.log("error", response);
                                } else if (response["type"] === "verification") {
                                    console.log(js_strings["error_verification"]);
                                    validator_login.general_error_show(js_strings["error_verification"]);
                                }
                            }
                        },
                        error: function (response) {
                            console.log("error", response);
                        }
                    });
                }
            }
        });

        // Password restore button
        $(".btn-restore").on("click", function (e) {
            e.preventDefault();
            var result = validator_restore.validate();
            if (result !== false) {
                result.append("locale", locale);
                result.append("_token", $('meta[name="csrf-token"]').attr("content"));
                console.log(baseUrl);
                ajax_request = $.ajax({
                    url: baseUrl + "/password/email",
                    type: "POST",
                    cache: false,
                    data: result,
                    processData: false,
                    contentType: false,
                    beforeSend: function () {
                    },
                    success: function (response) {
                        // response = JSON.parse(response)
                        if (response["status"] === "1") {
                            window.popup_close({cls: 'Autf', scrollOff: 'body'});
                            window.popup_set_type("ThankYou", "Success");
                            window.popup_show({cls: "ThankYou", optional_text: js_strings["success_password_reset"]});
                            // window.popup_set_type("Notification", "Success");
                            // window.popup_show({ cls: "Notification", optional_text: js_strings["success_password_reset"] });
                            //document.location = response['redirect'];
                        } else {
                            console.log("else error", response);
                            window.popup_set_type("ThankYou", "Fail");
                            window.popup_show({cls: "ThankYou", optional_text: js_strings["thankyou_error"]});
                            validator_restore.general_error_show(js_strings["error_wrong_email"]);
                            /*
                            if(response['type'] === 'wrong'){
                                validator.general_error_show(js_strings['error_wrong_credentials'])
                            }else if(response['type'] === 'validation'){
                                console.log("error",response);
                            }
                            */
                        }
                    },
                    error: function (response) {
                        console.log("error", response);
                    }
                });
            }
        });

        // Support button
        $(".btn-support").on("click", function (e) {
            e.preventDefault();
            var result = validator_support.validate();

            if (result !== false) {
                result.append("locale", locale);
                result.append("_token", $('meta[name="csrf-token"]').attr("content"));

                if (typeof grecaptcha !== 'undefined') {
                    grecaptcha.execute(SITE_KEY).then(function (token) {

                        captcha_token = token;

                        result.append("captcha_token", captcha_token);
                        ajax_request = $.ajax({
                            url: baseUrl + "/request_support",
                            type: "POST",
                            cache: false,
                            data: result,
                            processData: false,
                            contentType: false,
                            beforeSend: function () {
                            },
                            success: function (response) {
                                if (response["status"] === "1") {
                                    console.log("OK");
                                    window.popup_set_type("ThankYou", "Success");
                                    window.popup_show({cls: "ThankYou", optional_text: js_strings["success_support"]});
                                    // window.popup_set_type("Notification", "Success");
                                    // window.popup_show({ cls: "Notification", optional_text: js_strings["success_support"] });
                                    //document.location = response['redirect'];
                                } else {
                                    console.log("error", response);
                                    window.popup_set_type("ThankYou", "Fail");
                                    window.popup_show({cls: "ThankYou", optional_text: js_strings["fail_support"]});
                                    // window.popup_set_type("Notification", "Fail");
                                    // window.popup_show({ cls: "Notification", optional_text: js_strings["fail_support"] });
                                    validator_support.general_error_show(js_strings["fail_support"]);
                                    /*
                                    if(response['type'] === 'wrong'){
                                        validator.general_error_show(js_strings['error_wrong_credentials'])
                                    }else if(response['type'] === 'validation'){
                                        console.log("error",response);
                                    }
                                    */
                                }
                            },
                            error: function (response) {
                                console.log("error", response);
                            }
                        });
                    });
                } else {
                    ajax_request = $.ajax({
                        url: baseUrl + "/request_support",
                        type: "POST",
                        cache: false,
                        data: result,
                        processData: false,
                        contentType: false,
                        beforeSend: function () {
                        },
                        success: function (response) {
                            if (response["status"] === "1") {
                                console.log("OK");
                                window.popup_set_type("ThankYou", "Success");
                                window.popup_show({cls: "ThankYou", optional_text: js_strings["success_support"]});
                                // window.popup_set_type("Notification", "Success");
                                // window.popup_show({ cls: "Notification", optional_text: js_strings["success_support"] });
                                //document.location = response['redirect'];
                            } else {
                                console.log("error", response);
                                window.popup_set_type("ThankYou", "Fail");
                                window.popup_show({cls: "ThankYou", optional_text: js_strings["fail_support"]});
                                // window.popup_set_type("Notification", "Fail");
                                // window.popup_show({ cls: "Notification", optional_text: js_strings["fail_support"] });
                                validator_support.general_error_show(js_strings["fail_support"]);
                                /*
                                if(response['type'] === 'wrong'){
                                    validator.general_error_show(js_strings['error_wrong_credentials'])
                                }else if(response['type'] === 'validation'){
                                    console.log("error",response);
                                }
                                */
                            }
                        },
                        error: function (response) {
                            console.log("error", response);
                        }
                    });
                }
            }
        });

        //subscribe_news
        $(".btn-subscribe-news").on("click", function (e) {
            e.preventDefault();
            var result = validator_subscribe_news.validate();
            if (result !== false) {
                result.append("locale", locale);
                result.append("_token", $('meta[name="csrf-token"]').attr("content"));
                console.log(baseUrl);
                ajax_request = $.ajax({
                    url: baseUrl + "/subscribe_news",
                    type: "POST",
                    cache: false,
                    data: result,
                    processData: false,
                    contentType: false,
                    beforeSend: function () {
                    },
                    success: function (response) {
                        if (response["status"] === "1") {

                            // Analytics event
                            if (typeof gtag !== "undefined") {
                                gtag('event', 'send', {'event_category': 'subscribe_news', 'event_action': 'send',});
                            }

                            console.log("OK");
                            window.popup_set_type("ThankYou", "Success");
                            window.popup_show({cls: "ThankYou", optional_text: js_strings["success_subscribe_news"]});
                            // window.popup_set_type("Notification", "Success");
                            // window.popup_show({ cls: "Notification", optional_text: js_strings["success_subscribe_news"] });
                            //document.location = response['redirect'];
                        } else {
                            console.log("error", response);
                            window.popup_set_type("ThankYou", "Fail");
                            window.popup_show({cls: "ThankYou", optional_text: js_strings["fail_subscribe_news"]});
                            // window.popup_set_type("Notification", "Fail");
                            // window.popup_show({ cls: "Notification", optional_text: js_strings["fail_subscribe_news"] });
                            validator_subscribe_news.general_error_show(js_strings["fail_subscribe_news"]);

                            /*
                            if(response['type'] === 'wrong'){
                                validator.general_error_show(js_strings['error_wrong_credentials'])
                            }else if(response['type'] === 'validation'){
                                console.log("error",response);
                            }
                            */
                        }
                    },
                    error: function (response) {
                        console.log("error", response);
                    }
                });
            }
        });

        //RequestCall
        $(".btn-RequestCall").click(function (e) {
            e.preventDefault();
            console.log('AJAXREQUEST')
            console.log(ajax_request)
            var result = validator_RequestCall.validate();
            if (typeof ajax_request === 'undefined' && result !== false) {
                result.append("locale", locale);
                result.append("_token", $('meta[name="csrf-token"]').attr("content"));
                result.append("dial_code", $("#form-request-call").find($(".iti__selected-flag")).attr('title'));

                if (typeof grecaptcha !== 'undefined') {
                    grecaptcha.execute(SITE_KEY).then(function (token) {
                        result.append("captcha_token", token);
                        ajax_request = $.ajax({
                            url: baseUrl + "/request_call",
                            type: "POST",
                            cache: false,
                            data: result,
                            processData: false,
                            contentType: false,
                            beforeSend: function () {
                            },
                            success: function (response) {

                                window.popup_close({cls: "RequestCall", scrollOff: 'body'});
                                clearForm($('#form-request-call'));
                                if (response["status"] === "1") {
                                    console.log("OK");
                                    window.popup_set_type("ThankYou", "Success");
                                    window.popup_show({
                                        cls: "ThankYou",
                                        optional_text: js_strings["success_requestcall"]
                                    });

                                    // window.popup_set_type("Notification", "Success");

                                    // window.popup_show({ cls: "Notification", optional_text: js_strings["success_requestcall"] });
                                    //document.location = response['redirect'];


                                } else {
                                    console.log("error", response);
                                    window.popup_set_type("ThankYou", "Fail");
                                    window.popup_show({cls: "ThankYou", optional_text: js_strings["fail_requestcall"]});
                                    // window.popup_set_type("Notification", "Fail");
                                    // window.popup_show({ cls: "Notification", optional_text: js_strings["fail_requestcall"] });
                                    validator_RequestCall.general_error_show(js_strings["fail_requestcall"]);

                                    /*
                                    if(response['type'] === 'wrong'){
                                        validator.general_error_show(js_strings['error_wrong_credentials'])
                                    }else if(response['type'] === 'validation'){
                                        console.log("error",response);
                                    }
                                    */
                                }

                                setTimeout(() => {
                                    ajax_request = undefined;
                                }, 500)

                            },
                            error: function (response) {
                                console.log("error", response);
                                ajax_request = undefined;
                            }
                        });
                    })
                }
            }
        });

        // Schedule a complimentary call button
        $(".btn-ScheduleComplimentaryCall").on("click", function (e) {
            e.preventDefault();
            var result = validator_ScheduleComplimentaryCall.validate();
            if (typeof ajax_request === 'undefined' && result !== false) {
                result.append("locale", locale);
                result.append("_token", $('meta[name="csrf-token"]').attr("content"));
                result.append("dial_code", $("#form-ScheduleComplimentaryCall").find($(".iti__selected-flag")).attr('title'));
                if (typeof grecaptcha !== 'undefined') {
                    grecaptcha.execute(SITE_KEY).then(function (token) {
                        result.append("captcha_token", token);
                        console.log(baseUrl);
                        ajax_request = $.ajax({
                            url: baseUrl + "/schedule_support",
                            type: "POST",
                            cache: false,
                            data: result,
                            processData: false,
                            contentType: false,
                            beforeSend: function () {
                            },
                            success: function (response) {
                                if (response["status"] === "1") {
                                    console.log("OK");
                                    window.popup_set_type("ThankYou", "Success");
                                    window.popup_show({cls: "ThankYou", optional_text: js_strings["thankyou_success"]});

                                    clearForm($('#form-ScheduleComplimentaryCall'));
                                } else {
                                    // console.log("error", response);
                                    // validator_ScheduleComplimentaryCall.general_error_show(js_strings["error_wrong_email"]);

                                    window.popup_set_type("ThankYou", "Fail");
                                    window.popup_show({cls: "ThankYou", optional_text: js_strings["thankyou_error"]});
                                    validator_ScheduleComplimentaryCall.general_error_show(js_strings["error_wrong_email"]);

                                    /*
                                    if(response['type'] === 'wrong'){
                                        validator.general_error_show(js_strings['error_wrong_credentials'])
                                    }else if(response['type'] === 'validation'){
                                        console.log("error",response);
                                    }
                                    */
                                }

                                setTimeout(() => {
                                    ajax_request = undefined;
                                }, 500)
                            },
                            error: function (response) {
                                console.log("error", response);
                                ajax_request = undefined;
                            }
                        });
                    })
                }
            }
        });
        // Book Marchmaker’s assessment service button
        $(".btn-BookMarchmakersService").on("click", function (e) {
            e.preventDefault();
            var result = validator_BookMarchmakersService.validate();
            if (typeof ajax_request === 'undefined' && result !== false) {
                result.append("locale", locale);
                result.append("_token", $('meta[name="csrf-token"]').attr("content"));
                result.append("dial_code", $("#form-BookMarchmakersService").find($(".iti__selected-flag")).attr('title'));

                if (typeof grecaptcha !== 'undefined') {
                    grecaptcha.execute(SITE_KEY).then(function (token) {

                        captcha_token = token;
                        result.append("captcha_token", captcha_token);
                        ajax_request = $.ajax({
                            url: baseUrl + "/book_marchmakers_support",
                            type: "POST",
                            cache: false,
                            data: result,
                            processData: false,
                            contentType: false,
                            beforeSend: function () {
                            },
                            success: function (response) {
                                if (response["status"] === "1") {
                                    window.popup_set_type("ThankYou", "Success");
                                    window.popup_show({cls: "ThankYou", optional_text: js_strings["thankyou_success"]});
                                } else {
                                    window.popup_set_type("ThankYou", "Fail");
                                    window.popup_show({cls: "ThankYou", optional_text: js_strings["thankyou_error"]});
                                    window.popup_close({cls: "BookMarchmakersService", scrollOff: 'body'});
                                    validator_BookMarchmakersService.general_error_show(js_strings["error_wrong_email"]);
                                    /*
                                    if(response['type'] === 'wrong'){
                                        validator.general_error_show(js_strings['error_wrong_credentials'])
                                    }else if(response['type'] === 'validation'){
                                        console.log("error",response);
                                    }
                                    */
                                }

                                // grecaptcha.ready(function() {
                                //     grecaptcha.execute('6LfgKCQaAAAAAHtEEmsGLgWVoU0awmjpPdfpfCm1').then(function(token) {
                                //
                                //         captcha_token = token;
                                //
                                //     });
                                // });
                            },
                            error: function (response) {
                                console.log("error", response);
                            }
                        });
                    });
                } else {
                    ajax_request = $.ajax({
                        url: baseUrl + "/book_marchmakers_support",
                        type: "POST",
                        cache: false,
                        data: result,
                        processData: false,
                        contentType: false,
                        beforeSend: function () {
                        },
                        success: function (response) {

                            window.popup_close({cls: 'BookMarchmakersService', scrollOff: 'body'})
                            if (response["status"] === "1") {
                                window.popup_set_type("ThankYou", "Success");
                                window.popup_show({cls: "ThankYou", optional_text: js_strings["thankyou_success"]});

                                clearForm($('#form-BookMarchmakersService'));
                            } else {
                                window.popup_set_type("ThankYou", "Fail");
                                window.popup_show({cls: "ThankYou", optional_text: js_strings["thankyou_error"]});
                                window.popup_close({cls: "BookMarchmakersService", scrollOff: 'body'});
                                validator_BookMarchmakersService.general_error_show(js_strings["error_wrong_email"]);
                                /*
                                if(response['type'] === 'wrong'){
                                    validator.general_error_show(js_strings['error_wrong_credentials'])
                                }else if(response['type'] === 'validation'){
                                    console.log("error",response);
                                }
                                */
                            }

                            setTimeout(() => {
                                ajax_request = undefined;
                            }, 500)

                            // grecaptcha.ready(function() {
                            //     grecaptcha.execute('6LfgKCQaAAAAAHtEEmsGLgWVoU0awmjpPdfpfCm1').then(function(token) {
                            //
                            //         captcha_token = token;
                            //
                            //     });
                            // });
                        },
                        error: function (response) {
                            console.log("error", response);
                            ajax_request = undefined;
                        }
                    });
                }


            }
        });

    });
});

/* ---- Metrics and scripts ---- */
var fired = false;
const metricsFunc = function () {
    if (fired === false) {
        fired = true;

        var s = [];
        var sa = [];

        // s[0] = '<!-- Google Tag Manager -->\n' +
        //     '            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
        //     '                        new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
        //     '                    j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
        //     '                    \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
        //     '                })(window,document,\'script\',\'dataLayer\',\'GTM-PSGLBC2\');\n' +
        //     '                <!-- End Google Tag Manager -->';


        sa[1] = 'https://www.googletagmanager.com/gtag/js?id=G-69VS1V9S2L';
        s[1] = '<!-- Global site tag (gtag.js) - Google Analytics -->\n' +
            '                \n' +
            '                window.dataLayer = window.dataLayer || [];\n' +
            '                 console.log("window.dataLayer = ", window.dataLayer);' +
            '                function gtag(){dataLayer.push(arguments);}\n' +
            '                gtag(\'js\', new Date());\n' +
            '\n' +
            '                gtag(\'config\', \'G-69VS1V9S2L\');\n' +
            '                console.log("try")' +
            '            ';

        // sa[2] = 'https://www.googletagmanager.com/gtag/js?id=UA-185905609-1';
        // s[2] = '<!-- Global site tag (gtag.js) - Google Analytics (Sergey) -->\n' +
        //     '                \n' +
        //     '                window.dataLayer = window.dataLayer || [];\n' +
        //     '                function gtag(){dataLayer.push(arguments);}\n' +
        //     '                gtag(\'js\', new Date());\n' +
        //     '\n' +
        //     '                gtag(\'config\', \'UA-185905609-1\');\n' +
        //     '            ';

        // s[3] = '' +
        //     '    !function(f,b,e,v,n,t,s)\n' +
        //     '    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?\n' +
        //     '        n.callMethod.apply(n,arguments):n.queue.push(arguments)};\n' +
        //     '        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';\n' +
        //     '        n.queue=[];t=b.createElement(e);t.async=!0;\n' +
        //     '        t.src=v;s=b.getElementsByTagName(e)[0];\n' +
        //     '        s.parentNode.insertBefore(t,s)}(window, document,\'script\',\n' +
        //     '        \'https://connect.facebook.net/en_US/fbevents.js\');\n' +
        //     '    fbq(\'init\', \'442594026730391\');\n' +
        //     '    fbq(\'track\', \'PageView\');\n';


        var metricsHtml = "";

        sa.forEach(function (item, i, arr) {
            var newScript = document.createElement("script");
            newScript.async = true;
            newScript.src = item;
            document.body.appendChild(newScript);
        });
        s.forEach(function (item, i, arr) {
            var newScript = document.createElement("script");
            newScript.innerHTML = item;
            document.body.appendChild(newScript);
        });

        var scriptDiv = document.createElement("div");
        scriptDiv.innerHTML = metricsHtml;
        document.body.appendChild(scriptDiv);
        console.log("Metrics appended!")
    }
    //window.removeEventListener('scroll', metricsFunc);
};
//window.addEventListener('scroll', metricsFunc);
$(window).one('scroll.metrics click.metrics mousemove.metrics touchstart.metrics', function (e) {
    $(window).off("scroll.metrics click.metrics mousemove.metrics touchstart.metrics");
    metricsFunc();
});

/* ---- New user from socials ---- */
const urlParams = new URLSearchParams(window.location.search);
const newUserArrived = urlParams.get('new_user');
if (newUserArrived === 1) {
    // Analytics event
    if (typeof gtag !== "undefined") {
        gtag('event', 'send', {'event_category': 'lead', 'event_action': 'send',});
    }
}

/* ---- Clock ----- */

var clock_timer;
var clockContainer = document.getElementById("hours");

if (clockContainer !== null) {
    var clock_html = `
<div class="timer">
    <div class="timer-hours">
        <div class="hours"><span></span></div>
    </div>
    <div class="dot">
        <div class="dot__item"></div>
        <div class="dot__item"></div>
    </div>
    <div class="timer-min">
        <div class="min"><span></span></div>
    </div>
</div>
`;

    clockContainer.innerHTML = clock_html;

    var kiev_timestamp;
    var clock = function () {
        if (typeof kiev_timestamp == undefined) {
            var date = new Date().toLocaleTimeString("uk", {
                timeZone: "Europe/Kiev",
                hour: '2-digit',
                minute: '2-digit'
            });
        } else {
            var date = new Date(kiev_timestamp).toLocaleTimeString("uk", {
                timeZone: "Europe/Kiev",
                hour: '2-digit',
                minute: '2-digit'
            });
        }

        var [h, m] = date.split(":");
        //console.log("TEST_CLOCK", h+" : "+m);


        var clockHours = document.querySelectorAll("#hours .hours span")[0] || false;
        var clockMins = document.querySelectorAll("#hours .min span")[0] || false;
        if (!clockHours || !clockMins) {
            clearInterval(clock_timer);
            return
        }


        clockHours.innerHTML = h;
        clockMins.innerHTML = m;
    };

    setTimeout(function () {
        console.log("clock v2");
        var data = new FormData();
        data.append("locale", locale);
        data.append("_token", $('meta[name="csrf-token"]').attr("content"));
        ajax_request = $.ajax({
            url: baseUrl + "/api/get_kiev_time",
            type: "POST",
            cache: false,
            data: data,
            processData: false,
            contentType: false,
            beforeSend: function () {
            },
            success: function (response) {
                if (response["status"] === "1") {
                    kiev_timestamp = response['content'] * 1000;
                    //date = new Date(response["content"]).toLocaleTimeString("uk", {timeZone: "Europe/Kiev", hour: '2-digit', minute: '2-digit'});
                    clock_timer = setInterval(function () {
                        clock();
                    }, 1000);
                } else {
                    clock_timer = setInterval(function () {
                        clock();
                    }, 1000);
                }
            },
            error: function (response) {
                console.log("error", response);
            }
        });
    }, 500);

}
/* ---- Clock END ----- */

/*---- mobile_menu -- */
$(".mobile_menu_btn").click(function () {
    if ($(".mobile_Nav, .mobile_hider").hasClass("active")) {
        $(".mobile_Nav").removeClass("active");
        $(".mobile_hider").removeClass("active");
        $(this).removeClass("active");
        $(".main_menu")
            .css("height", "100px")
            .removeClass("super_active");

        bodyUnfixPosition();
    } else {
        $(".mobile_Nav").addClass("active");
        $(this).addClass("active");
        $(".main_menu")
            .css("height", "100%")
            .addClass("super_active");
        $(".mobile_hider").addClass("active");

        bodyFixPosition();
    }
});

// $(".mobile_menu_overlay").click(function() {
//     $(".mobile_Nav").removeClass("active");
//     $(".mobile_menu_overlay").removeClass("active");
// });
$(".mobile_hider").click(function () {
    $(".mobile_menu_btn").removeClass('active');
    $(".mobile_hider").removeClass("active");
    $(".mobile_Nav").removeClass("active");
    $(".main_menu")
        .css("height", "100px")
        .removeClass("super_active");

    bodyUnfixPosition();
});
/*---- mobile_menu -- */

//popup
require("../../views/blocks/popup/popup");
require("../../views/blocks/popup/pay/pay");
// require("../../views/blocks/popup/popup_video");
$(window).one('scroll.once click.once mousemove.once touchstart.once', function (e) {
    $(window).off("scroll.once click.once mousemove.once touchstart.once");
    import(
        /* webpackChunkName: "popup_video" */
        `../../views/blocks/popup/popup_video`
        ).then(function (module) {
        const PopupVideo = module.default;
        PopupVideo();
    });
});
// active tab popup auntif
$(".btn-sing-in").on("click", function () {
    $("#form-reg").css("display", "none");
    $(".Log_Link").css("display", "none");

    $("#form-login").css("display", "block");
    $(".Reg_Link").css("display", "block");

    $("#form-restore").css("display", "none");
    $(".Restore_Link").css("display", "none");

    window.popup_show({cls: "Autf", scrollOff: "body"});
    //$('.Reg_Link').click();
});

$(".btn-sing-up").on("click", function () {
    $("#form-login").css("display", "none");
    $(".Reg_Link").css("display", "none");

    $("#form-restore").css("display", "none");
    $(".Restore_Link").css("display", "none");

    $("#form-reg").css("display", "block");
    $(".Log_Link").css("display", "block");

    window.popup_show({cls: "Autf", scrollOff: "body"});
    //$('.Reg_Link').click();
});

//polit
require("./global_modal_politica");

// active lang
$(".language_item_fn").on("click", function (e) {
    e.preventDefault();
    sessionStorage.setItem(
        "lang",
        $(this)
            .text()
            .toLowerCase()
    );
    document.location.href = $(this).attr("href");
    return false;
});

//add info to popup pay
$('body').on('click', '.BtnPayFn', function () {
    console.log("I am clicked!");

    let price_enabled = false;

    let _this = $(this);
    let id = _this.data('id-product');
    let name = _this.data('name-product');
    let price = _this.data('price-product');
    let currency = _this.data('currency');
    let payment_type = _this.data('payment-type');
    let user_id = _this.data('user-id');
    let data = _this.data('data');
    let id_form = $('#form-pay');

    if (price === "") {
        price_enabled = true;
    }

    id_form.find('input[name=id_product]').val(id);
    id_form.find('input[name=name_product]').val(name);
    id_form.find('input[name=price_product]').val(price);
    if (price_enabled) {
        id_form.find('input[name=price_product]').prop("disabled", false);
    }
    id_form.find('input[name=currency]').val(currency);
    id_form.find('input[name=payment_type]').val(payment_type);
    id_form.find('input[name=user_id]').val(user_id);
    id_form.find('input[name=data]').val(data);


    id_form.find('input[name=who_is_the_gift_service]').parents('.form-group').css('display', 'none')
});
// $('.BtnPayFn').click(function () {
//
// });

// Check for status={status} in query string (thankyou popup)
window.paymentStatus = "none";
if (typeof window.location.search === 'string' && window.location.search.indexOf("status=") !== -1) {
    let params = new URLSearchParams(window.location.search);
    window.paymentStatus = params.get('status');
    window.paymentPopupType = params.get('popup_type');
    console.log(window.paymentPopupType);

    if (window.paymentStatus === "success") {
        window.popup_set_type("ThankYou", "Success");
        switch (window.paymentPopupType) {
            case "first_consultation_ordered":
                window.popup_show({
                    cls: "ThankYou",
                    optional_text: (window.userName || "Customer") + js_strings["thankyou_success_first_consultation_ordered"]
                });
                break;

            default:
                window.popup_show({cls: "ThankYou", optional_text: js_strings["thankyou_success"]});
                break;
        }

    } else if (window.paymentStatus === "error") {
        window.popup_set_type("ThankYou", "Fail");
        window.popup_show({cls: "ThankYou", optional_text: js_strings["thankyou_error"]});
    }
}

// Toggle svg Danil single_services 09.11.2020

$('.select_pay').click(function () {
    $('.select_arrow').toggleClass('select_arrow_click');
    console.log('clicked!');
});

$('.select_pay').focusout(function () {
    $('.select_arrow').removeClass('select_arrow_click');
});


$(".btn-agree").on("click", function () {
    setCookie("policy", true, 120);
    $(".cookies-popup").fadeOut("slow");
});

$(".exclude_exp").on("keypress", function (e) {
    console.log(e.which);
    if (e.which !== 13 && e.which != 8 && e.which != 0 && e.which < 48 || e.which > 57) {
        e.preventDefault();
    }
});
// jq_slider_icons_phone(
// 	5000,
// 	".Contact_Info_Icon_Slider",
// 	".Contact_Info_Icon_Slider_Item"
// );
function jq_slider_icons_phone(time, par_class, class_el) {

    let sliders = $(par_class);
    for (var i = 0; i < sliders.length; i++) {
        slides(sliders.eq(i));
    }

    function slides(item) {
        var icon_list = item.children(class_el);
        var index_show = -1;
        setInterval(() => {
            console.log("swadawdwadaw");
            index_show++;
            for (let i = 0; i < icon_list.length; i++) {
                requestAnimationFrame(function anim() {
                    icon_list.css({"opacity": "0", "visibility": "hidden"});
                });
            }

            if (index_show >= icon_list.length) {
                index_show = 0;
            }
            requestAnimationFrame(function anim() {
                icon_list.eq(index_show).css({"opacity": "1", "visibility": "visible"});
            });

        }, time);
    }

}

$('.print_btn').click(function () {
    let _this = $(this);
    let divId = _this.data("divid");

    PrintContent.print_content(divId);
});

function clearForm(form) {

    if (typeof form !== 'undefined') {
        form[0].reset()
    }

    //clear all label, remove class label_active

    form.find('.label').each((index, element) => {
        $(element).removeClass('label_active')
    })
}

