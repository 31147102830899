$(".btn-reg-fn").click(function(e) {
    e.preventDefault();
});

/* modal aunif exchange*/
$(".show-new-modal").on("click", function() {
    let _this = $(this),
        data = _this.data("showModal"),
        arr_data = data.split("|");

    $(".hider_class").slideUp("slow", function() {
        setTimeout(function() {
            $(`.${arr_data[1]}`).fadeIn("slow");
            $(`.${arr_data[0]}`).fadeIn("slow");
            $(`#${arr_data[2]}`).slideDown("slow");
        }, 1000);
    });

    console.log(arr_data);
});
