// require("../libs/jq_input_mas");

// Include outside of document ready
$(document).ready(function() {
    //console.log($('.datepicker-input'));
    // $('.datepicker-input').datepicker({
    // 	minDate: new Date()
    // });

    // Fix for disabling autocomplete
    $(".autofill-disable").attr("readonly", false);

    // Label lift (active)
    $(".input").focusin(function() {
        var parent = $(this).parent();
        parent.children(".label").addClass("label_active");
        //autocomplete_check();
    });

    // Label lift (inactive)
    $(".input").focusout(function() {
        var parent = $(this).parent();

        if ($(this).val().length <= 0) {
            parent.children(".label").removeClass("label_active");
        }
    });

    // Focus input, when clicked on label
    $(".label").on("click", function() {
        $(this)
            .siblings("input")
            .focus();
    });

    // Focus textarea, when clicked on label
    $(".textarea_label").on("click", function() {
        $(this)
            .siblings("textarea")
            .focus();
    });

    //inputmask
    // $("input[name='birth_date']").inputmask("datetime", {
    //     inputFormat: "dd | mm | yyyy",
    //     // mask: "99/99/9999",
    //     //"mask": "mm\\months",
    //     alias: "mm/dd/yyyy",
    //     separator: " | ",
    //     // "autoUnmask" : true,
    //     "clearIncomplete": false,
    //     "showMaskOnHover": false
    // });
    //
    let dateNow = new Date();
    const date = dateNow.getDate() > 9 ? dateNow.getDate() : "0" + dateNow.getDate();
    const month = dateNow.getMonth() + 1 > 9 ? dateNow.getMonth() + 1 : "0" + (dateNow.getMonth() + 1);
    const year = dateNow.getFullYear();
    const hours = dateNow.getHours() > 9 ? dateNow.getHours() : "0" + dateNow.getHours();
    const minutes = dateNow.getMinutes() > 9 ? dateNow.getMinutes() : "0" + dateNow.getMinutes();

    const dateSetMask = date + "/" + month + "/" + year + " " + hours + ":" + minutes;
    console.log(dateSetMask);

    Promise.all([
        import(
            /* webpackChunkName: "inputmask" */
            `../libs/jq_input_mas`
            ),
    ]).then(function (modules) {
        const [inputmaskModule] = modules;
        const inputmask = inputmaskModule.default;

        $(".date-input").inputmask( {
            alias: "datetime",
            inputFormat: "dd/mm/yyyy HH:MM",
            // min: "01/01/2000",
            min: date+"/"+month+"/"+year,
            //"placeholder":"__/__/____",
            clearIncomplete: false,
            showMaskOnHover: false
        });
    })


    // $('input[name="phone"]').keydown(function(event) {
    //     console.log('phone')
    //     // Разрешаем: backspace, delete, tab и escape
    //     if ( event.keyCode == 46 || event.keyCode == 8 || event.keyCode == 9 || event.keyCode == 27 ||
    //         // Разрешаем: Ctrl+A
    //         (event.keyCode == 65 && event.ctrlKey === true) ||
    //         // Разрешаем: home, end, влево, вправо
    //         (event.keyCode >= 35 && event.keyCode <= 39)) {
    //
    //         // Ничего не делаем
    //         return;
    //     } else {
    //         // Запрещаем все, кроме цифр на основной клавиатуре, а так же Num-клавиатуре
    //         if ((event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105 )) {
    //             event.preventDefault();
    //         }
    //     }
    // });

    // $("input[name='phone']").inputmask({
    //     mask: "+99 (999) 999-99-99",
    //     clearIncomplete: false,
    //     showMaskOnHover: false
    // });
    // $("input[name='birth_date']").inputmask();

    //END inputmask

    //autocomplete_check();
});

// Not needed. Use autofill-disable class
function autocomplete_check() {
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var autofill = isChrome === true ? $("input:-webkit-autofill").length : 0;

    var inputs = $(".input");
    for (var i = 0; i < inputs.length; i++) {
        if (inputs.eq(i).val().length > 0 || autofill > 0) {
            var parent = inputs.eq(i).parent();
            parent.children(".label").addClass("label_active");
        }
    }
}

