
// Spinner JS

/*
Functions:
    spinner_on      - Insert spinner in container. Returns spinner element
    spinner_off     - Removes spinner
*/

// Example usage
/*

// let spinner;
// setTimeout(() => {
//     spinner = window.spinner_on({
//         containerQuery: "#payment-container",
			disabledClass: ""
//         spinnerBackgroundAdditionalClasses: "bgclass",
//     });
// }, 5000);
// setTimeout(() => {
//     window.spinner_off({
			spinnerQueryOrElement: spinner,
			containerQuery: "#payment-container",
			disabledClass: ""
			})
// }, 10000)

*/

let spinnerHtml = `
<div class="spinner_background">
    <svg class="spinner__svg" viewBox="0 0 50 50">
      <circle class="spinner__svg__path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
    </svg>
</div>
`;

window.spinner_on = function({
	containerQuery = null,
	spinnerBackgroundAdditionalClasses = "",
     disabledClass = ""
} = {}) {
    if(containerQuery === null){
        console.warn("[spinner] query not defined");
        return null;
    }

    let container = $(containerQuery);
	container.addClass(" "+disabledClass);
    let spinner = $(spinnerHtml);
    spinner.addClass(" "+spinnerBackgroundAdditionalClasses);
    let spinnerBackground = spinner.find(".spinner_background");
    spinnerBackground.addClass(" "+spinnerBackgroundAdditionalClasses);
    spinner.appendTo(container);
    return spinner;
};

window.spinner_off = function spinner_off({
	spinnerQueryOrElement,
	containerQuery = null,
	disabledClass = ""
} = {}){
    if(typeof spinnerQueryOrElement === "object"){
        spinnerQueryOrElement.remove();
    }else{
        $(spinnerQueryOrElement).find(".spinner_background").remove();
    }

	if(containerQuery === null || typeof $(containerQuery) === "undefined"){
		console.warn("[spinner] query not defined");
	}else{
		$(containerQuery).removeClass(disabledClass)
	}

};
