
window.popup_pay_show = popup_pay_show;

// Possible params:
function popup_pay_show(data){
    if(typeof data.price === "undefined" || typeof data.currency === "undefined"){
        console.warn("popup_pay_show: not enough info for payment popup");
        return;
    }

    if(typeof data.payment_type === "undefined"){
        data.payment_type = 'default';
    }

    let form = $("#form-pay");

    form.find('input[name=price_product]').val(data.price);
    form.find('input[name=currency]').val(data.currency);
    form.find('input[name=payment_type]').val(data.payment_type);

    form.find('input[name=name_product]').val(data.title);
    form.find('input[name=who_is_the_gift_service]').parents('.form-group').css("display", "none");

    console.log(data);
    // Data to JSON format
    if(typeof data === "object"){
        data = JSON.stringify(data);
    }
    console.log(data);

    form.find('input[name=data]').val(data);


    popup_show({cls: "Pay"});
}
